import React from 'react';
import ReactDOM from 'react-dom/client';
import {Router} from "./routing/Router";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";

const App = () => <Router />

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18n}>
        <App/>
    </I18nextProvider>
);